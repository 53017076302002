import React, { useState } from "react";
import Text from "common/components/Text";
import Image from "common/components/Image";
import Button from "common/components/Button";
import Heading from "common/components/Heading";
import Input from "common/components/Input";
import Container from "common/components/UI/ContainerTwo";
import BannerWrapper, {
  BannerContent,
  Subscribe,
  SponsoredBy,
  ImageGroup,
} from "./banner.style";

import paypal from "common/assets/image/agencyModern/paypal.png";
import google from "common/assets/image/agencyModern/google.png";
import dropbox from "common/assets/image/agencyModern/dropbox.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Banner = () => {
  const [email, setEmail] = useState("");

  const submit = async () => {
    toast.success("We will recontact you " + email, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    await axios.post(
      "https://api.gaviota.fr/mail",
      {
        to: "jerome.le.champion@gmail.com",
        subject: "New Email from landing page Gaviota ✔",
        body: email,
      },
      {
        headers: {
          Authorization: "API_KEY_JEROME",
          "Content-Type": "application/json",
        },
      }
    );
  };
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Heading
            as="h1"
            content="Build your audience &amp; grow your business online smarter"
          />
          <Text
            className="banner-caption"
            content="Agencies around the world are moving to the digital agencies. So, It is high time to introduce your agency digitaly. We respect our customer opinions and deals with them with perfect wireframing."
          />
          <Subscribe>
            <Input
              inputType="email"
              placeholder="Enter Email Address"
              iconPosition="left"
              aria-label="email"
              onChange={setEmail}
            />
            <Button
              style={{ backgroundColor: "#ff825c", minWidth: "150px" }}
              title="Subscribe"
              onClick={submit}
            />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Subscribe>
          <SponsoredBy>
            <Text className="sponsoredBy" content="Sponsored by:" />
            <ImageGroup>
              <Image src={paypal} alt="Paypal" />
              <Image src={google} alt="Google" />
              <Image src={dropbox} alt="Dropbox" />
            </ImageGroup>
          </SponsoredBy>
        </BannerContent>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
