import React, { useState } from "react";

import Container from "common/components/UI/ContainerTwo";
import CheckBox from "common/components/Checkbox";
import Heading from "common/components/Heading";
import Button from "common/components/Button";
import Input from "common/components/Input";
import Image from "common/components/Image";
import Text from "common/components/Text";

import SectionWrapper, {
  FooterInner,
  Content,
  SubscriptionForm,
} from "./subscribe.style";

import bg1 from "common/assets/image/agencyModern/cta/1.png";
import bg2 from "common/assets/image/agencyModern/cta/2.png";
import bg3 from "common/assets/image/agencyModern/cta/3.png";
import bg4 from "common/assets/image/agencyModern/cta/4.png";
import bg5 from "common/assets/image/agencyModern/cta/5.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Subscribe = () => {
  const [email, setEmail] = useState("");

  const submit = async () => {
    toast.success("We will recontact you " + email, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    await axios.post(
      "https://api.gaviota.fr/mail",
      {
        to: "jerome.le.champion@gmail.com",
        subject: "New Email from landing page Gaviota ✔",
        body: email,
      },
      {
        headers: {
          Authorization: "API_KEY_JEROME",
          "Content-Type": "application/json",
        },
      }
    );
  };
  return (
    <SectionWrapper>
      <Container>
        <FooterInner>
          <Content>
            <Heading as="h3" content="Like our service? Subscribe us" />
            <Text content="We have more than thousand of creative entrepreneurs and stat joining our business" />
          </Content>
          <SubscriptionForm>
            <div>
              <Input
                inputType="email"
                placeholder="Enter Email Address"
                iconPosition="left"
                aria-label="email"
                onChange={setEmail}
              />
              <Button
                style={{ backgroundColor: "#ff825c", minWidth: "150px" }}
                title="Subscribe"
                onClick={submit}
              />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
            <CheckBox
              id="remember"
              htmlFor="remember"
              labelText="Don’t provide any promotional message."
            />
          </SubscriptionForm>
        </FooterInner>
      </Container>
      <Image src={bg1} alt="bg1" className="illustration bg1" />
      <Image src={bg2} alt="bg2" className="illustration bg2" />
      <Image src={bg3} alt="bg3" className="illustration bg3" />
      <Image src={bg4} alt="bg4" className="illustration bg4" />
      <Image src={bg5} alt="bg5" className="illustration bg5" />
    </SectionWrapper>
  );
};

export default Subscribe;
